// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

const firebaseConfig = {
    apiKey: 'AIzaSyD1vh1W5ZpCKzcHTiNVnhAo-zCoRQjfPDY',
    authDomain: 'buildton-d5141.firebaseapp.com',
    projectId: 'buildton-d5141',
    storageBucket: 'buildton-d5141.firebasestorage.app',
    messagingSenderId: '1044774416158',
    appId: '1:1044774416158:web:fd13f1f2c63be91feba30f'
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
//const analytics = getAnalytics(app);


export const generateToken = async () => {
    let token = null

    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === "granted") {
        token = await getToken(messaging, {
            vapidKey: "BHbt6PnA94BvJ7Jt2620C6abGIQrirNKdOGv64y3JngeYD1ZQ7-4fMPArS1fFyxytQX4XMDOhqWoa-u7HgncE0s"

        })
        console.log(token);
        return token;
    }

}